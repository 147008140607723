import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { startOfToday, sub, eachDayOfInterval, format } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

const TagsOverTime = ({ data }) => {
    const startDate = new Date(2020, 2, 1); // March 1, 2020
    const endDate = startOfToday(); // Today, start of day

    const colorPalette = [
        'rgba(255, 179, 71, 0.6)',   // pastel orange
        'rgba(140, 233, 140, 0.6)',  // pastel green
        'rgba(255, 105, 97, 0.6)',   // pastel red
        'rgba(190, 141, 236, 0.6)',  // pastel purple
        'rgba(135, 206, 250, 0.6)'   // pastel blue
      ];

    const chartData = useMemo(() => {
    
        const datasets = data.map((tag, index) => {
            
            const validItems = tag.items.filter(i => i != null)
            const sortedItems = validItems.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          
            const counts = {};
            sortedItems.forEach(item => {
              if (!item) {
                console.log(`Found an item without data.`);
                return;
              }
              const dateStr = item.node.createdAt.split('T')[0];
              counts[dateStr] = (counts[dateStr] || 0) + 1;
            });

            const allDates = sortedItems.map(item => new Date(item.node.createdAt));
            const startDate = new Date(Math.min(...allDates));
            const endDate = new Date();

            const dateRange = eachDayOfInterval({ start: startDate, end: endDate });
            
            const filledCounts = dateRange.map(date => {
              const formattedDate = format(date, 'yyyy-MM-dd');
              return {
                x: formattedDate,
                y: counts[formattedDate] || 0 // Use the count or 0 if no uploads
              };
            });

            const borderColor = colorPalette[index % colorPalette.length];
          
            return {
              label: tag.name,
              data: filledCounts,
              borderColor: borderColor,
              fill: false,
              tension: 0
            };
          });
    
        return {
          labels: [startDate, endDate],
          datasets,
        };
      }, [data]);
    

      const options = {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'year',
              tooltipFormat: 'yyyy-MM-dd',
              min: startDate,
              max: endDate,
            },
            title: {
              display: true,
              text: 'Date'
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Upload Count'
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Image Uploads Over Time by Tag',
          },
          legend: {
            display: true,
          }
        },
      };
    

  const wrapperStyle = {
    minHeight: '600px'
  };

  return (
    <div style={wrapperStyle}>
        <Line data={chartData} options={options} />
    </div>
  );
};

export default TagsOverTime;
